import { Component, Prop, Mixins, Watch } from 'vue-property-decorator'
import { Validator } from '@/App/Mixins'
import { default as dayjs } from 'dayjs'
import i18n from '@/plugins/i18n'

@Component
export default class DatePicker extends Mixins(Validator) {
  @Prop({ default: false })
  public clearable: boolean

  @Prop({ default: false })
  public required: boolean

  @Prop({ default: i18n.tc('pickers.datePicker.defaultLabelText') })
  public title: string

  @Prop({ default: false })
  public dark: boolean

  @Prop({ default: '' })
  public value: string

  public menu = false
  public date = dayjs().format('YYYY-MM-DD')

  @Watch('value')
  private valueChanged(val: string): void {
    this.date = val
  }

  private mounted():void {
    if (this.value) {
      this.date = this.value
    }
  }
}
